body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #0d1117;
  color: #c9d1d9;
}

.miniapp {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.miniapp-header {
  background-color: #161b22;
  color: #c9d1d9;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  border-bottom: 1px solid #30363d;
}

.miniapp-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chat-message {
  margin: 0.5rem 0;
  padding: 0.75rem;
  border-radius: 12px;
  max-width: 75%;
  font-size: 0.9rem;
  display: inline-block;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #238636;
  color: white;
}

.chat-message.chatgpt {
  align-self: flex-start;
  background-color: #161b22;
  color: #c9d1d9;
}

.chat-input {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #30363d;
}

.chat-input input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #30363d;
  border-radius: 6px;
  background-color: #0d1117;
  color: #c9d1d9;
}

.chat-input input:focus {
  outline: none;
  border-color: #238636;
}

.chat-input button {
  margin-left: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #238636;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #2ea043;
}

.miniapp-footer {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem;
  background-color: #161b22;
  border-top: 1px solid #30363d;
}

.miniapp-footer button {
  flex: 1;
  margin: 0 0.25rem;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  background-color: #0d1117;
  color: #c9d1d9;
  cursor: pointer;
}

.miniapp-footer button.active {
  background-color: #238636;
  color: white;
}

.miniapp-footer button:hover {
  background-color: #30363d;
}
