/* src/components/NotFound.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
}

.not-found-content {
  max-width: 600px;
}

.not-found-content h1 {
  font-size: 6rem;
  margin: 0;
  color: #ff4c4c;
}

.not-found-content h2 {
  font-size: 2rem;
  margin-top: 10px;
  color: #333;
}

.not-found-content p {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #555;
}

.back-home-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-home-btn:hover {
  background-color: #0056b3;
}

.gif-container {
  margin-top: 30px;
}

.not-found-gif {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
