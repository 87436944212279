/* Home.css */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero {
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #7f7fd5);
  background-size: 800% 800%;
  animation: gradientAnimation 12s ease-in-out infinite;
  color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-in-out;
}

.hero p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1.5s ease-in-out;
}

.hero .btn {
  margin: 0 0.5rem;
  padding: 0.85rem 1.8rem;
  font-size: 1.3rem;
  transition: background-color 0.3s, transform 0.3s;
}

.hero .btn:hover {
  transform: scale(1.1);
}

@keyframes fadeInUp {
  0% {
      opacity: 0;
      transform: translateY(50px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Projects & Reviews */
#projects, #demo, #reviews {
  padding: 4rem 0;
  background-color: #f7f9fc;
}

#projects .card, #reviews .card {
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease;
}

#projects .card:hover, #reviews .card:hover {
  transform: scale(1.05);
}

#projects .card-title, #reviews .card-title {
  font-size: 1.6rem;
}

#reviews blockquote {
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 1rem;
}

#reviews footer {
  font-size: 0.95rem;
  color: #6c757d;
}

/* Project buttons */
.project-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

img {
  animation: reveal 1.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes reveal {
  0% {
      opacity: 0;
      transform: scale(0.9);
      filter: brightness(2);
  }
  100% {
      opacity: 1;
      transform: scale(1);
      filter: brightness(1);
  }
}

/* Adaptive Styles */
@media (max-width: 768px) {
  .hero h1 {
      font-size: 2.8rem;
  }

  .hero p {
      font-size: 1.4rem;
  }

  .hero .btn {
      font-size: 1.1rem;
      padding: 0.7rem 1.2rem;
  }

  #projects .card, #reviews .card {
      margin-bottom: 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero h1 {
      font-size: 2.4rem;
  }

  .hero p {
      font-size: 1.2rem;
  }

  .hero .btn {
      font-size: 0.95rem;
      padding: 0.5rem 1rem;
  }

  #projects .card, #reviews .card {
      margin-bottom: 1rem;
  }
}
