.info-page {
    background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #7f7fd5);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.info-card {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 32px rgba(243, 0, 0, 0.5);
    border-radius: 10px;
    padding: 30px;
    opacity: 0.95;
    transition: box-shadow 0.3s ease-in-out;
}

.info-card:hover {
    box-shadow: 0 12px 40px rgba(243, 0, 0, 0.7);
}

.profile-photo {
    box-shadow: 0 8px 32px rgba(243, 0, 0, 0.7);
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: none !important;
    opacity: 1 !important;
}

.info-text p {
    margin: 10px 0;
    color: #343a40;
    font-size: 16px;
}

.info-text a {
    margin-right: 10px;
    color: #007bff;
    text-decoration: none;
}

.info-text a:hover {
    text-decoration: underline;
}
