/* App.css */
.navbar-custom {
  background: linear-gradient(90deg, #343a40, #444); /* Градиентный фон */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
}

.navbar-custom .navbar-nav .nav-link {
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  transition: color 0.3s ease-in-out; /* Плавный переход */
  padding: 0.75rem 1.25rem;
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #86a8e7; /* Современный цвет при наведении */
}

.navbar-custom .navbar-brand {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  transition: color 0.3s ease-in-out;
}

.navbar-custom .navbar-brand:hover {
  color: #feb47b; /* Цвет логотипа при наведении */
}

.navbar-custom .navbar-toggler {
  border: none; /* Убираем границу */
}

.navbar-custom .navbar-toggler-icon {
  filter: invert(1); /* Переворачиваем цвет иконки */
}
