/* Основной фон страницы с градиентом и анимацией */
.payment-page {
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #7f7fd5);
  background-size: 400% 400%; /* Это позволит фону плавно перемещаться */
  animation: gradientAnimation 15s ease infinite;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Анимация смены градиента */
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Карточка оплаты с эффектом размытия (glassmorphism) */
.payment-card {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px);
}

/* Стили для заголовка */
.payment-card .text-center {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* Поле формы */
.payment-card .form-label {
  font-weight: bold;
  color: #343a40;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.custom-btn {
  background-color: #343a40;
  border-color: #343a40;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  transition: transform 0.1s ease, background-color 0.1s ease;
}

.custom-btn:hover {
  transform: scale(1.03);
}

.user-agreement {
  background-color: #d1deeb;
  border-color: #343a40;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  transition: transform 0.1s ease, background-color 0.1s ease;
}

.user-agreement:hover {
  transform: scale(1.03);
}

/* Кнопка */
.payment-card .btn {
  background-color: #343a40;
  border-color: #343a40;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  transition: transform 0.2s, background-color 0.2s;
}

/* Анимация при наведении на кнопку */
.payment-card .btn:hover {
  transform: scale(1.05);
  background-color: #495057;
}

/* Оптимизация для мобильных устройств */
@media (max-width: 768px) {
  .payment-card {
    padding: 15px;
  }
}

/* Стили для группы переключателей (ToggleButtonGroup) */
.toggle-btn-group .btn-outline-primary {
  color: #007bff;
  border-radius: 30px;
  border: 2px solid #007bff;
  transition: background-color 0.2s, color 0.2s;
}

/* Анимация переключателя при выборе */
.toggle-btn-group .btn-outline-primary.active, .toggle-btn-group .btn-outline-primary:focus {
  background-color: #007bff;
  color: white;
}

/* Плавные переходы между элементами */
.payment-card .form-control {
  transition: border-color 0.2s, box-shadow 0.2s;
}

.payment-card .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button-group .btn {
  background-color: #343a40;
  border-color: #343a40;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  transition: transform 0.2s, background-color 0.2s;
}

.button-group .btn:hover {
  transform: scale(1.05);
  background-color: #495057;
}

.button-group .btn:active {
  transform: scale(0.98);
}